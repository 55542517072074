import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileUpdateService {
  /**
   * A private BehaviorSubject that holds the current message status.
   * The initial value is set to `false`.
   */
  private messageSource = new BehaviorSubject<boolean>(false);

  /**
   * Observable for the current message status, allowing components to subscribe and react to changes.
   */
  currentMessage = this.messageSource.asObservable();

  /**
   * Constructor for the ProfileUpdateService.
   */
  constructor() {}

  /**
   * Updates the current message status with the given boolean value.
   * @param {boolean} message - The new message status to be set.
   */
  changeMessage(message: boolean): void {
    this.messageSource.next(message);
  }
}
